import React, { Component } from 'react';
import { connect } from 'react-redux';

import LabelledInput from './LabelledInput';
import Select from './Select';

import { createSalesTargets, createUpdateSalesTarget } from '../actions';
import { closePopup } from '../actions/popup';

import { parseMysqlDate, formatMoney } from '../utils';

import { getUserOptions } from '../selectors/dropdowns';

import { BASE_ZINDEX } from '../popup-factory';

class EditSalesTargetPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            selected_user: props.client_rep_id,
            user_sales_targets: props.sales_targets[props.client_rep_id] ? Object.values(props.sales_targets[props.client_rep_id])
                .map(s => ({...s, sales_target_value: formatMoney(s.sales_target_value, 0)}))
                .reduce((o, s) => { o[s.sales_target_id] = s; return o;}, {}) : null,
            sales_targets: props.sales_targets
        };

        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.onEditSalesTarget = this.onEditSalesTarget.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.checkSalesTargets();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            user_sales_targets: Object.values(nextProps.sales_targets[this.state.selected_user])
                .map(s => ({ ...s, sales_target_value: formatMoney(s.sales_target_value, 0) }))
                .reduce((o, s) => { o[s.sales_target_id] = s; return o; }, {}),
            sales_targets: nextProps.sales_targets,
            loading: false
        });
    }

    checkSalesTargets() {
        if (this.state.sales_targets[this.state.selected_user]) {
            let count = Object.values(this.state.sales_targets[this.state.selected_user]).length;
            if (count < 12) {
                this.props.onCreateSalesTargets(this.state.selected_user);
            } else {
                if (this.state.sales_targets[this.state.selected_user]) {
                    this.setState({
                        user_sales_targets: Object.values(this.state.sales_targets[this.state.selected_user])
                            .map(s => ({ ...s, sales_target_value: formatMoney(s.sales_target_value, 0) }))
                            .reduce((o, s) => { o[s.sales_target_id] = s; return o; }, {}),
                        loading: false
                    });
                }
            }
        }else{
            this.props.onCreateSalesTargets(this.state.selected_user);
        }
    }

    handleChangeUser(value) {
        this.setState({ selected_user: value, loading: true }, function() {
            this.checkSalesTargets();
        });
    }

    onEditSalesTarget(e, sales_target_id) {
        let user_sales_target = Object.assign({}, this.state.user_sales_targets[sales_target_id], { 'sales_target_value': e });
        let user_sales_targets = Object.assign({}, this.state.user_sales_targets, { [sales_target_id]: user_sales_target });

        this.setState({ user_sales_targets: user_sales_targets });
    }

    renderLoading() {
        return (
            <div className='row popup-content column'>
                <div className='small-12 columns'>
                    <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                </div>
            </div>
        );
    }

    render() {
        const { users, onCreateUpdateSalesTarget, onClosePopup, index } = this.props;

        return (
            <div id='edit-sales-target-modal' className='reveal small' style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <h3 id='modalTitle'>Edit Sales Target</h3>
                        <a className='alert button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Close</a>
                    </div>
                </div>
                <div className='row popup-content'>
                    <Select className="columns medium-12" label='User' options={users} value={this.state.selected_user} onChange={e => this.handleChangeUser(e.value)} />
                    {this.state.loading ?
                        this.renderLoading()
                        : this.state.user_sales_targets ? Object.values(this.state.user_sales_targets).map(s =>
                            <LabelledInput
                                className="columns medium-6"
                                key={s.sales_target_id}
                                label={parseMysqlDate(s.sales_target_time, '0000-00', true)}
                                value={s.sales_target_value}
                                placeholder="Target amount of the month"
                                onChange={e => this.onEditSalesTarget(e, s.sales_target_id)}
                                onBlur={onCreateUpdateSalesTarget(s.sales_target_id)}
                            />
                            )
                            : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        sales_targets: state.entities.sales_targets,
        users: getUserOptions(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup());
        },
        onCreateSalesTargets: (client_rep_id) => {
            dispatch(createSalesTargets(client_rep_id));
        },
        onCreateUpdateSalesTarget: (sales_target_id) => sales_target_value => {
            dispatch(createUpdateSalesTarget(sales_target_id, sales_target_value));
        }
    };
};

const ConnectedEditSalesTargetPopup = connect(mapStateToProps, mapDispatchToProps)(EditSalesTargetPopup);
export default ConnectedEditSalesTargetPopup;